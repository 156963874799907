html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

h1 {
  margin-top: 20px;
  text-align: center;
}

.sponsor-section {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  margin: 10px; /* To add a little space from the edge */
}